.App {
  text-align: center;
}
body {
  background-image: linear-gradient(
      to bottom,
      rgb(0, 0, 0, 0.2),
      rgb(0, 0, 0, 0.2)
    ),
    url("bg2.png") !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  background-position: center top !important;
}
.gif__container {
  width: 100%;
}
.gif {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  height: auto;
  margin-bottom: 3vh;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.651) 0px 15px 25px;
}
.logo__image {
  width: 16%;
  margin-left: 42%;
  margin-right: 42%;
  height: auto;
  margin-bottom: 25vh;
  margin-top: 2vh;
}
